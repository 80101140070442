// Colour Palette
$background-color: #282C34;
$gradient-1: #285467;
$gradient-2: #00818A;
$gradient-3: #2FAF91;
$gradient-4: #8CD881;
$gradient-5: #F9F871;

$play-height: 18rem;

// Content ----------------------------------

.app {
  background-color: $background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: white;
  font-size: 1rem;

  & > * {
    margin: 0.25rem;
  }
}

// Helpers

.hidden {
  visibility: hidden;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;

  &:hover {
    background-color: $gradient-2;
  }
}

// Sections ----------------------------------

.play {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  border-style: solid;
  border-color: $gradient-3;
  border-radius: 2.5%;
  border-width: 1px;
  text-align: center;
  height: $play-height;
  overflow: hidden;
}

.finances {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  border-radius: 2.5%;
  border-style: solid;
  border-color: $gradient-3;
  border-radius: 2.5%;
  border-width: 1px;
  text-align: center;
  height: $play-height;
}

.researchTeam {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  text-align: center;
  border-radius: 2.5%;
  border-style: solid;
  border-color: $gradient-3;
  border-radius: 2.5%;
  border-width: 1px;
  height: $play-height;
}

.devToolbar {
  display: flex;
  flex-direction: column;
  width: 10rem;
  position: fixed;
  right: 0;
  bottom: 0;
}

// Misc ---------------------------------

label {
  font-size: 0.8rem;
}

.clicker {
  margin: 0.25rem;
  text-align: center;
  background-color: $gradient-2;
  color: white;
  padding: 0.5rem;
  border-radius: 5%;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:hover {
    background-color: $gradient-3;
  }
}

.saleButtons {
  display: flex;
  justify-content: center;
}
